exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-concierge-js": () => import("./../../../src/pages/concierge.js" /* webpackChunkName: "component---src-pages-concierge-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-property-new-developments-js": () => import("./../../../src/pages/property/new-developments.js" /* webpackChunkName: "component---src-pages-property-new-developments-js" */),
  "component---src-pages-rentals-js": () => import("./../../../src/pages/rentals.js" /* webpackChunkName: "component---src-pages-rentals-js" */),
  "component---src-pages-where-to-stay-hotels-js": () => import("./../../../src/pages/where-to-stay/hotels.js" /* webpackChunkName: "component---src-pages-where-to-stay-hotels-js" */),
  "component---src-pages-where-to-stay-villas-js": () => import("./../../../src/pages/where-to-stay/villas.js" /* webpackChunkName: "component---src-pages-where-to-stay-villas-js" */),
  "component---src-templates-concierge-js": () => import("./../../../src/templates/concierge.js" /* webpackChunkName: "component---src-templates-concierge-js" */),
  "component---src-templates-hotel-js": () => import("./../../../src/templates/hotel.js" /* webpackChunkName: "component---src-templates-hotel-js" */),
  "component---src-templates-listing-js": () => import("./../../../src/templates/listing.js" /* webpackChunkName: "component---src-templates-listing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-templates-rentals-js": () => import("./../../../src/templates/rentals.js" /* webpackChunkName: "component---src-templates-rentals-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-trend-area-js": () => import("./../../../src/templates/trendArea.js" /* webpackChunkName: "component---src-templates-trend-area-js" */)
}

